<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>仓库信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card aaass">
      <el-button type="primary" @click="opentan()">添加信息</el-button>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="date" sortable label="创建时间" width="150"></el-table-column>
        <el-table-column prop="house_no" label="仓库编号" width="130"></el-table-column>
        <el-table-column prop="house_name" label="仓库名称" width="150"></el-table-column>
        <el-table-column prop="type_desc" label="仓库类型" width="130"></el-table-column>
        <!-- <el-table-column prop="status_desc" label="仓库状态" width="130"></el-table-column> -->
        <el-table-column prop="house_desc" label="仓库描述" width="130"></el-table-column>
        <el-table-column prop="house_remark" label="仓库备注" width="130"></el-table-column>
        <el-table-column prop="area_name" label="仓库地区" width="180"></el-table-column>
        <el-table-column prop="address" label="仓库详细地址" width="180"></el-table-column>

        <el-table-column label="仓库状态" width="100px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-switch
              class="switch"
              :value="scope.row.house_status"
              :active-value="1"
              :inactive-value="0"
              @change="ones(scope.row.id, $event)"
              active-text="启用"
              inactive-text="关闭"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row)"></el-button>
            <!-- <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>

    <el-dialog title="仓库信息" :visible.sync="dialogFormVisible" :before-close="handleClose" width="38%">
      <el-form :model="form" ref="form" label-width="100px" :rules="storeRules">
        <el-form-item label="仓库名称" prop="house_name" label-width="150px">
          <div class="el-select"><el-input v-model="form.house_name" placeholder="请填写仓库名称"></el-input></div>
        </el-form-item>
        <el-form-item label="仓库类型" label-width="150px" prop="storehouse_type">
          <el-select v-model="form.storehouse_type" placeholder="未选择" :disabled='falg'>
            <el-option v-for="item in leilist" :key="item.value" :label="item.name" :value="item.value" @click.native="chenlei(item.value)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库编号" label-width="150px" v-if="tantype == 'edit'">
          <div class="el-select"><el-input v-model="form.house_no" placeholder="仓库编号" :disabled='falg'></el-input></div>
        </el-form-item>
        <el-form-item label="仓库描述" label-width="150px">
          <div class="el-select"><el-input v-model="form.house_desc" placeholder="请输入仓库简介"></el-input></div>
        </el-form-item>
        <el-form-item label="仓库备注" label-width="150px">
          <div class="el-select"><el-input v-model="form.house_remark" placeholder="请输入仓库备注"></el-input></div>
        </el-form-item>
        <el-form-item label="仓库地区" label-width="150px" prop="area_code">
          <el-cascader ref="tree" @change="optpidfun" v-model="form.area_code" :options="usetypelist" clearable :props="casprops" :disabled='falg'></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" label-width="150px">
          <div class="el-select"><el-input v-model="form.address" placeholder="仓库详细地址"></el-input></div>
        </el-form-item>

        <el-form-item label="仓库负责人" label-width="150px" >
          <el-select v-model="form.principal_user" placeholder="未选择">
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="cangguan(item.value)"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否个人库" label-width="150px">
          <el-radio v-model="form.is_person" label="0" @change="dan1" :disabled='falg'>否</el-radio>
          <el-radio v-model="form.is_person" label="1" @change="dan2" :disabled='falg'>是</el-radio>
        </el-form-item>

        <el-form-item label="个人仓归属人" label-width="150px" v-if="form.is_person == 1" prop="user_id">
          <el-select v-model="form.user_id" placeholder="未选择">
            <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value" @click.native="choicedrug(item.value)"></el-option>
          </el-select>
        </el-form-item>
        <!-- {{ form }} -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quanxiao()">取 消</el-button>
        <el-button type="primary" @keyup.enter.native="quanque()" @click="quanque(tantype)">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { storeRules } from '../../../utils/storeRules'
import { Area } from '../../../api/pact'
import { Getren } from '../../../api/shouhuo'
import { Canglei, Xinjian, Getcanglist, Setcang, Jincang, Qicang } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      falg:false,
      tableData: [],                 // 列表数据
      tantype: 'add',
      dialogFormVisible: false,
      renlist: [],                   // 人员列表
      leilist: [],                   // 仓库类型
      form: {
        house_name: '',              // 仓库名称
        area_code: '',               // 仓库地区码
        house_desc: '',              // 仓库简介
        house_remark: '',            // 仓库备注
        storehouse_type: '',         // 仓库类型
        is_person: '0',              // 是否个人库
        user_id: '',                 // 个人库归属人
        address: '',                 // 仓库详细地址
        house_no: '',                // 仓库编号
        principal_user:'',           // 仓库负责人
      },
      usetypelist: [],               // 地区级联地区数据
      casprops: { value: 'code', label: 'area_name', checkStrictly: false, children: 'list' }, // 地区级联选择的配置
      storeRules,
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.Getlists()
  },
  methods: {
    // 仓库负责人选中
    cangguan(e){
      console.log(e);
    },
    // 打开弹窗
    opentan() {
      this.dialogFormVisible = true
      this.Areas()
      this.Getrenlist()
      this.Cangleixing()
      this.falg = false
    },
    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false
      this.quanxiao()
    },
    // 获取客户地区编码
    optpidfun(e) {
      this.form.area_code = e[e.length - 1] === undefined ? 0 : e[e.length - 1]
    },
    // 单选
    dan1(e) {
      //   console.log(e)
      this.form.user_id = ''
    },
    dan2(e) {
      //   console.log(e)
    },
    // 人员选中
    choicedrug(e) {
      // console.log(e)
      this.form.user_id = e
    },
    // 仓库类型选中
    chenlei(e) {
      // console.log(e)
    },

    ones(id, e) {
      console.log('当前id' + id, '当前状态是' + e)
      switch (e) {
        case 0:
          console.log('禁用')
          this.$confirm('是否禁用此仓库, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.Endlists(id)
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消禁用'
              })
            })
          break
        case 1:
          console.log('启用')
          this.$confirm('是否启用用此仓库, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.Qiyong(id)
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消启用'
              })
            })
          break

        default:
          break
      }
    },

    // 添加取消
    quanxiao() {
      // this.$refs.form.resetFields()
      this.form.is_person = '0'
      this.form.area_code = ''
      this.form.address = ''
      this.form.house_desc = ''
      this.form.house_remark = ''
      this.dialogFormVisible = false
      this.tantype = 'add'
      this.form.house_name = ''
      this.form.storehouse_type = ''
      this.form.id = ''
      this.form.house_status = ''
      this.form.status_desc = ''
      this.form.type_desc = ''
      this.form.person_desc = ''
      this.form.person_desc = ''
      this.form.area_name = ''
      this.form.user_id = ''
      this.form.date = ''
      this.form.user_name = ''
      this.falg = true
      this.form.principal_user = ''
    },

    // 编辑
    editfun(ev) {
      // console.log(ev)
      this.form = JSON.parse(JSON.stringify(ev))
      this.tantype = 'edit'
      this.opentan()
      this.falg = true
    },

    // 删除
    delfun(id) {
      // console.log(id)
    },

    // 确定添加
    quanque(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['form'].validate(v => {
            if (v) {
              this.ClientInformations(this.form)
            }
          })
          break
        case 'edit':
          this.$refs['form'].validate(v => {
            if (v) {
              this.editcompanyfun(this.form)
            }
          })
          console.log(type)
          break
      }
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlists()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlists()
    },

    ///////////请求区//////////////

    // 地区联动
    async Areas() {
      const { data } = await Area()
      this.usetypelist = data.data.list
    },

    // 人员列表
    async Getrenlist() {
      const { data } = await Getren({is_workno:1})
      //   console.log(data)
      this.renlist = data.data
    },

    // 仓库类型
    async Cangleixing() {
      const { data } = await Canglei()
      // console.log(data)
      this.leilist = data.data
    },

    // 新建仓库
    async ClientInformations(v) {
      const { data } = await Xinjian(v)
      this.getanpostfun(data, '添加', this.quanxiao(), this.Getlists())
    },

    // 拉取仓库列表
    async Getlists() {
      const { data } = await Getcanglist({ ...this.pageData })
      // console.log(data)
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 修改仓库
    async editcompanyfun(v) {
      const { data } = await Setcang(v)
      this.getanpostfun(data, '修改', this.Getlists())
    },

    // 禁用仓库
    async Endlists(id) {
      const { data } = await Jincang({ id })
      this.getanpostfun(data, '禁用仓库', this.Getlists())
    },
    // 启用仓库
    async Qiyong(id) {
      const { data } = await Qicang({ id })
      this.getanpostfun(data, '启用仓库', this.Getlists())
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
/* switch按钮样式 */
.switch .el-switch__label {
  position: absolute;
  display: none;
  font-size: 12px !important;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch .el-switch__label--left span {
  margin-left: 18px;
}
/*显示文字*/
.switch .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 48px !important;
  margin: 0;
}
.el-switch__label .el-switch__label--right .is-active span {
  font-size: 12px;
}
.el-switch__label * {
  font-size: 12px !important;
}
</style>