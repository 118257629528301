
// 验证规则
const storeRules = {
    // 仓库名称
    house_name: [
        { required: true, message: '请输入仓库名称', trigger: 'blur' },
        { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
    ],
    // 仓库简介
    house_desc: [
        { required: true, message: '请输入仓库描述', trigger: 'blur' },
        { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
    ],
    // 仓库备注
    house_remark: [
        { required: true, message: '请输入仓库备注', trigger: 'blur' },
        { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
    ],
    // 仓库详细地址
    address: [
        { required: true, message: '请输入仓库地址', trigger: 'blur' },
        { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
    ],
    // 仓库类型
    storehouse_type: [{ required: true, message: '请选择仓库类型', trigger: 'change' }],
    // 仓库归属人
    user_id: [{ required: true, message: '请选择仓库归属人', trigger: 'change' }],
    // 仓库地区
    area_code: [
        { required: true, message: "所属地区不能为空", trigger: "change" },
    ],

}

export { storeRules }
